<template>
  <v-app id="admindashboard">
    <v-content>
      <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
        ></loading>
        <v-container app fluid>
          <v-flex xs12 sm12 md12>
            <v-card pt-4 pb-4 flat dark color="blue lighten-1" class="justify-center">
              <div style="text-align:center">
                <v-card-title primary class="justify-center display-1 hidden-sm-and-down">
                  Admin Dashboard
                </v-card-title>
                <v-card-title primary class="justify-center title hidden-md-and-up">
                  Admin Dashboard
                </v-card-title>
              </div>
            </v-card>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-toolbar dark color="blue lighten-1">
              <v-toolbar-title>
                Active EcoPledges
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="uSearch"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>

            <v-layout style="height:90%;" column>
              <v-data-table
                :headers="usersHeaders"
                :items="allUsers"
                :search="uSearch"
                :pagination.sync="pagination1"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <!-- <td scope="col" width="10%">
                    <v-icon small class="mr-2" @click="editItem(props.item)">edit</v-icon>
                  </td> -->
                  <!-- <td scope="col" class="text-xs-left" width="10%">{{ props.item.displayName }}</td> -->
                  <td scope="col" class="text-xs-left" width="5%">{{ props.item.email }}</td>
                  <td scope="col" class="text-xs-center" width="25%">{{ props.item.epStartDate.seconds * 1000 | formatDate }}</td>
                  <td scope="col" class="text-xs-center" width="25%">{{ props.item.epEndDate.seconds * 1000 | formatDate }}</td>
                  <td scope="col" class="text-xs-center" width="5%">{{ props.item.epTotalDays }}</td>
                  <td scope="col" class="text-xs-center" width="5%">{{ props.item.epCurrDay }}</td>
                  <td scope="col" class="text-xs-center" width="5%">{{ props.item.inProgress }}</td>

                </template>
                <template v-slot:no-results>
                  <v-alert
                    :value="true"
                    color="error"
                    icon="warning"
                  >Your search for '{{ uSearch }}' found no results.</v-alert>
                </template>
              </v-data-table>
            </v-layout>
          </v-flex>

          <!-- <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-text>
                <v-container grid-list-md>
                  <p>User - Update</p>
                  <div style="height:20px"></div>
                  <v-spacer />
                  <v-text-field large v-model.number="enteredTarget" label="Name" />
                  <div style="height:20px"></div>
                  <v-text-field v-model.number="enteredTarget" label="Email" />
                  <div style="height:20px"></div>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" flat @click="dialog = false">Cancel</v-btn>
                <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->

          <div style="height:40px"></div>



          <!-- <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <div class="justify-center">
              <v-toolbar dark color="blue lighten-1">
                <v-toolbar-title>
                  Collective
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="sSearch"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-btn
                  round
                  dark
                  class="button"
                  :loading="loading3"
                  :disabled="loading3"
                  color="blue lighten-2"
                  @click.prevent="onAddSchool"
                >add school</v-btn>
              </v-toolbar>
            </div>
            <v-layout style="height:90%;" column>
              <v-data-table
                :headers="schoolsHeaders"
                :items="ecopledges"
                :search="sSearch"
                :pagination.sync="pagination2"
              >
                <template slot="items" slot-scope="props">
                  <td class="justify-center">
                    <v-icon small class="mr-2" @click="editItem(props.item)">edit</v-icon>
                  </td>
                  <td>{{ props.item.epCurrDay }}</td>
                  <td>{{ props.item.epTotalDays }}</td>
                  <td>{{ props.item.inProgress }}</td>

                </template>
                <template v-slot:no-results>
                  <v-alert
                    :value="true"
                    color="error"
                    icon="warning"
                  >Your search for '{{ sSearch }}' found no results.</v-alert>
                </template>
              </v-data-table>
            </v-layout>
          </v-flex> -->

          <v-dialog v-model="dialog2" max-width="500px">
            <v-card>
              <v-card-text>
                <v-container grid-list-md>
                  <p>Edit User</p>
                  <div style="height:20px"></div>
                  <v-spacer />
                  <v-text-field large v-model.number="enteredTarget" label="Name" />
                  <div style="height:20px"></div>
                  <v-text-field v-model.number="enteredActual" label="Start Date" />
                  <div style="height:20px"></div>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" flat @click="dialog = false">Cancel</v-btn>
                <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div style="height:40px"></div>

          <!-- <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <div class="justify-center">
              <v-toolbar dark color="blue lighten-1">
                <v-toolbar-title>EcoPledge Types</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </div> -->
            <!-- <v-card light>
              <doughnut></doughnut>
            </v-card>-->
          <!-- </v-flex> -->
          <!-- <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <div style="height:40px"></div>
            <div class="justify-center">
              <v-toolbar dark color="blue lighten-1">
                <v-toolbar-title>Combined Effect</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </div> -->
            <!-- <v-card light>
              <line-chart></line-chart>
            </v-card>-->
            <!-- <div style="height:80px"></div>
          </v-flex> -->
              <div class="extras text-xs-center">
                <v-btn
                  large
                  round
                  dark
                  color="blue accent-4"
                  @click="onSignOut"
                >
                  Sign Out
                </v-btn>
              </div>
        </v-container>
      </div>
    </v-content>
  </v-app>
</template>

<script>
// import Doughnut from '@/components/chart/Doughnut'
// import LineChart from '@/components/chart/LineChart'
import { mapState, mapActions, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import firebase from 'firebase/app'
import moment from 'moment'

export default {
  name: 'AdminDashboard',
  data() {
    return {
      allUsers: [],
      allUser: null,

      isLoading: false,
      fullPage: true,
      loading3: false,
      uSearch: '',
      sSearch: '',

      enteredTarget: 0,
      enteredActual: 0,
      dialog: false,
      dialog2: false,
      snack: false,
      snackColor: '',
      snackText: '',

      epSchool: [],
      // users: [],
      schoolsList: [],

      pagination1: {},
      pagination2: {},
      usersHeaders: [
        // { text: 'EDIT', value: 'actions', align: 'center' },
        // { text: 'NAME', value: 'displayName', align: 'left' },
        { text: 'EMAIL', value: 'email', align: 'left' },
        { text: 'FROM', value: 'epStartDate', align: 'center' },
        { text: 'TO', value: 'epEndDate', align: 'center' },
        { text: 'TOTAL DAYS', value: 'epTotalDays', align: 'center' },
        { text: 'CURRENT DAY', value: 'epCurrDay', align: 'center' },
        { text: 'ACTIVE', value: 'inProgress', align: 'center' }
      ],
      schoolsHeaders: [
        { text: 'Actions', value: 'actions', align: 'center' },
        { text: 'School', value: 'name', align: 'center' },
        { text: 'Type', value: 'type', align: 'center' },
        { text: 'Target', value: 'target', align: 'center' },
        { text: 'Actual', value: 'actual', align: 'center' }
      ]
    }
  },
  components: {
    // Doughnut,
    // LineChart,
    Loading
  },
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
    ...mapState('authentication', ['user']),
    ...mapState('authentication', ['users']),
    // ...mapGetters('authentication', ['getUsersId']),
    // ...mapGetters('ecopledges', ['getEcopledgeById']),
    ...mapState('ecopledges', ['ecopledges']),
    ...mapState('ecopledges', ['ecopledge']),

    // currentUsers() {
    //   console.log(
    //     'currentUsers: ' + this.$store.state.authentication.user.currEcopledge
    //   )
    //   // return this.$store.state.authentication.user
    //   // return this.$store.dispatch('authentication/getUsersId')
    //   return this.getUsersId(this.$store.state.authentication.user.userId)`
    // },

    // currentEcopledge() {``
    //   console.log(
    //     'currentEcopledge: ' +
    //       this.$store.state.authentication.user.currEcopledge
    //   )
    //   return this.getEcopledgeById(
    //     this.$store.state.authentication.user.currEcopledge
    //   )
    // },

    myStyles() {
      return {
        height: '300px'
      }
    },
    pages1() {
      if (
        this.pagination1.rowsPerPage == null ||
        this.pagination1.totalItems == null
      ) {
        return 0
      }
      return Math.ceil(
        this.pagination1.totalItems / this.pagination1.rowsPerPage
      )
    },
    pages2() {
      if (
        this.pagination2.rowsPerPage == null ||
        this.pagination2.totalItems == null
      ) {
        return 0
      }
      return Math.ceil(
        this.pagination2.totalItems / this.pagination2.rowsPerPage
      )
    }
  },
  // created: function() {
  //   this.doAjax()
  //   this.$store.dispatch('ecopledges/getUserEcopledges')
  //   this.$store.dispatch('ecopledges/getEcopledge')
  //   this.$store.dispatch('authentication/getUsers')
  //   this.$store.dispatch('authentication/getUsersId')
  // },
  mounted() {
    this.isLoading = true
    // location.reload(true);
    console.log("admin: mounted()")
    this.$store.dispatch('ecopledges/getUserEcopledges')
    this.$store.dispatch('ecopledges/getEcopledge')
    this.$store.dispatch('authentication/getUsers')
    // location.reload(false);
    this.isLoading = false
  },

    created() {
        const self = this;
        firebase.auth().onAuthStateChanged(function(userAuth) {
            self.user = userAuth;
            if (userAuth) {
                firebase
                    .auth()
                    .currentUser.getIdTokenResult()
                    .then(tokenResult => {
                        console.log('admindashboard-onAuthStateChanged:' + tokenResult.claims);
                    });
            }
        });

        this.allUsers = [];
        firebase
            .firestore()
            .collection("users")
            .get()
            .then(snap => {
                snap.forEach(doc => {
                  const user = doc.data()
                  user.id = doc.id
                  console.log("userId: " + user.id)
                  const todosRef = firebase
                      .firestore()
                      .collection("users")
                      .doc(user.id)
                      .collection("ecopledges")
                      
                  todosRef.onSnapshot(snap => {
                      // this.allUsers = [];
                      snap.forEach(doc => {
                          const todo = doc.data();
                          todo.id = doc.id;
                          todo.email = user.email;
                          if (todo.inProgress) {
                            this.allUsers.push(todo);
                          }
                      });
                  })


                  // firebase
                  // .firestore()
                  // // .collection("users")
                  // // .doc(user.id)
                  // .collection("ecopledges")
                  //   .get()
                  //   .then(querySnapshot => {

                  //     const documents = querySnapshot.docs.map(doc => doc.data())
                  //     this.allUsers.push(doc.data())
                  //   })


                  // firebase
                  //   .firestore()
                  //   .collection("ecopledges")
                  //   .get()
                  //   .then(snap => {
                  //     snap.forEach(doc => {
                  //       console.log("user-ecopledge: " + doc.data)
                  //     this.allUsers.push(doc.data())
                  //   })
                  // })
                    // user.displayName = 'displayName'
                    console.log(doc.data());
                    // // if (!user.role.admin) this.allUsers.push(user);
                    // this.allUsers.push(user);
                // });
            })
            })
    },

  methods: {
    ...mapActions('authentication', ['getUsers']),
    // ...mapActions('authentication', ['getUsersId']),
    ...mapActions('ecopledges', ['getUserEcopledges']),
    ...mapActions('ecopledges', ['getEcopledge']),
    ...mapActions('ecopledges', ['updateEcopledge']),

    async onSignOut() {
      await firebase.auth().signOut()
    },
    
    onAddSchool() {
      console.log('in onAddSchool')
      for (let i = 0; i < 12; i += 1) {
        const recordToCreate = {
          createDate: Date(),
          name: 'Tuen Mun Memorial Primary school',
          epId: 'tXXBWYxn3TQjRcKDTsJ1',
          userId: '0Gezo1DiIXWM8fKkWSCHsaqBgQd2',
          type: 'Power',
          label: i + 1,
          valueTarget: Math.round(i * 100 + Math.random() * 20),
          valueActual: Math.round(i * 100 + Math.random() * 20)
        }
        this.$store.dispatch('records/triggerAddRecordAction', recordToCreate)
      }
    },

    saveInline() {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancelInline() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    openInline() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    closeInline() {
      console.log('Dialog closed')
    },
    // object.assign fills in the empty object with the properties of item
    editItem(item, dbox = true) {
      console.log('...in editItem()')
      // this.schoolId = item.id
      this.enteredTarget = Number(item.valueTarget)
      this.enteredActual = Number(item.valueActual)
      this.editedIndex = this.currentUsers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = dbox
    },
    editItem2(item, dbox = true) {
      console.log('...in editItem()')
      // this.schoolId = item.id
      this.enteredTarget = Number(item.valueTarget)
      this.enteredActual = Number(item.valueActual)
      this.editedIndex = this.ecopledges.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog2 = dbox
    },
    save() {
      // console.log('...in save()' + this.schoolId + this.enteredTarget + this.enteredActual,);
      // db.currentSchool.doc(this.schoolId).update({
      //   valueTarget: this.enteredTarget,
      //   valueActual: this.enteredActual
      // })
      this.close()
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    doAjax() {
      this.isLoading = true
      // simulate AJAX
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
  // mounted() {
  //   const vm = this;
  //   const loader = this.$loading.show();
  //   vm.$fbBindAsArray(
  //     'fbArray',
  //     // firebaseDb.ref('Demo/products').limitToLast(100)
  //     db.ref('Demo/products').orderByChild('type').equalTo(this.targetProdType),
  //   ).then(() => {
  //     loader.hide();
  //   });
  // },
  // computed: {
  //   ...mapState(['userProfile', 'currentUser']),
  //   myStyles() {
  //     return {
  //       height: '300px'
  //     }
  //   },
  //   pages1() {
  //     if (
  //       this.pagination1.rowsPerPage == null ||
  //       this.pagination1.totalItems == null
  //     ) {
  //       return 0
  //     }
  //     return Math.ceil(
  //       this.pagination1.totalItems / this.pagination1.rowsPerPage
  //     )
  //   },
  //   pages2() {
  //     if (
  //       this.pagination2.rowsPerPage == null ||
  //       this.pagination2.totalItems == null
  //     ) {
  //       return 0
  //     }
  //     return Math.ceil(
  //       this.pagination2.totalItems / this.pagination2.rowsPerPage
  //     )
  //   }
  // }
}
</script>

<style lang="scss" scoped>
#admindashboard {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c5034;
  margin-top: 0px;
}
</style>

<template>
  <v-app id="ourdashboard">
    <v-content>
      <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
        ></loading>
        <v-container app fluid>
          <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <v-card pt-4 pb-4 flat color="green accent-4" dark class="justify-center">
              <div style="text-align:center">
                <v-card-title
                  primary
                  class="justify-center display-1 hidden-sm-and-down"
                >School Dashboard</v-card-title>
                <v-card-title
                  primary
                  class="justify-center display-1 hidden-md-and-up"
                >School Dashboard</v-card-title>
              </div>
            </v-card>
          </v-flex>

          <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <div>
              <v-toolbar dark color="green accent-4">
                <v-toolbar-title>
                  {{
                  this.user.email
                  }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <div class="justify-center">
                <v-toolbar dark caption color="green accent-4">
                  <v-toolbar-title>our EcoPledge</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </div>
              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-text>
                    <v-container grid-list-md>
                      <p>{{ $t('ourDashboard.MonthShort' + 1) }}</p>
                      <div style="height:20px"></div>
                      <v-spacer />
                      <v-text-field large v-model.number="enteredTarget" suffix="KWh" label="Target" />
                      <div style="height:20px"></div>
                      <v-text-field v-model.number="enteredActual" suffix="KWh" label="Actual" />
                      <div style="height:20px"></div>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" flat @click="dialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-layout style="height:90%;" column>
                <v-data-table
                  :headers="currentHeaders"
                  :items="records"
                  :sort-by="['label']"
                  :sort-desc="[false, true]"
                  hide-actions
                  class="elevation-20"
                >
                  <template slot="items" slot-scope="props">
                    <td align="center">{{ $t('ourDashboard.MonthShort' + props.item.label) }}</td>
                    <td align="center">{{ props.item.valueTarget }}</td>
                    <td align="center">{{ props.item.valueActual }}</td>
                    <td align="center">
                      <v-icon small @click="editItem(props.item)">edit</v-icon>
                    </td>
                  </template>
                </v-data-table>
              </v-layout>
            </div>
          </v-flex>

          <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <div class="justify-center">
              <v-toolbar dark color="green accent-4">
                <v-toolbar-title>Graph</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </div>
          </v-flex>
          <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <v-card light>
              <!-- <line-chart></line-chart> -->
              <bar-chart v-if="loaded" :styles="myStyles" :chart-data="message" class="elevation-0"></bar-chart>
            </v-card>
          </v-flex>
          <div style="height:40px"></div>

          <v-flex xs12 sm8 md8 offset-sm2 offset-md2>
            <div class="justify-center">
              <v-toolbar dark color="green accent-4">
                <v-toolbar-title>previous EcoPledges</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </div>
            <v-layout style="height:90%;" column>
              <v-data-table
                :headers="previousHeaders"
                :items="ecopledges"
                hide-actions
                class="elevation-20"
                height="200px"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.startDate | formatDate }}</td>
                  <td>{{ props.item.epTotalDays }}</td>
                  <td>{{ props.item.inProgress }}</td>
                </template>
              </v-data-table>
            </v-layout>
          </v-flex>

          <div style="height:20px"></div>

          <v-flex xs12 sm8 md6 offset-md2 offset-sm2>
            <div style="height:80px"></div>
          </v-flex>
        </v-container>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import Vue from 'vue'
// import LineChart from '@/components/chart/LineChart'
import { mapState, mapActions, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { Bar, mixins } from 'vue-chartjs'

Vue.component('bar-chart', {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  data: () => ({
    message: 'Yellow',
    gradient: null,
    loaded: true,

    options: {
      // Chart.js options
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              stepSize: 100,
              fontSize: 14
            },
            gridLines: {
              display: false,
              lineWidth: 1
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 0.8,
            // barThickness: 60,
            // maxBarThickness: 8,
            minBarLength: 2,
            gridLines: {
              display: false
            },
            ticks: {
              fontSize: 16
            }
          }
        ]
      },
      legend: {
        display: true
      },
      responsive: true,
      maintainAspectRatio: false
    }
  }),
  mounted() {
    this.loaded = false
    console.log('in mounted()')
    this.renderChart(this.chartData, this.options)
    this.loaded = true
  }
})
export default {
  name: 'OurDashboard',
  data() {
    return {
      loaded: true,
      isLoading: false,
      fullPage: true,
      enteredTarget: 0,
      enteredActual: 0,

      dialog: false,
      snack: false,
      snackColor: '',
      snackText: '',

      max15chars: v => v.length <= 15 || 'Input too long!',
      pagination: {},

      datacollection: {},
      message: {},
      chartdata: {},
      chartData: null,
      chartOptions: null,
      gradient: null,

      reuseResults: [],
      plasticsResults: [],

      c_labels: [],
      c_reuseData: [],
      c_plasticsData: [],

      currentHeaders: [
        { text: 'Month', value: 'label', align: 'center' },
        { text: 'Target', value: 'valueTarget', align: 'center' },
        { text: 'Actual', value: 'valueActual', align: 'center' },
        { text: 'Edit', value: 'valueActual', align: 'center' }
      ],
      previousHeaders: [
        { text: 'Start Date', value: 'createDate', align: 'center' },
        { text: 'Duration', value: 'epTotalDays', align: 'center' }
      ],
      editedIndex: -1,
      editedItem: {
        id: '',
        valueTarget: 1,
        valueActual: 1
      },
      defaultItem: {}
    }
  },
  components: {
    Loading
  },

  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
    ...mapState('authentication', ['user']),
    ...mapGetters('ecopledges', ['getEcopledgeById']),
    ...mapState('ecopledges', ['ecopledges']),
    ...mapState('ecopledges', ['ecopledge']),
    ...mapState('records', ['records']),

    currentEcopledge() {
      console.log(
        'currentEcopledge: ' +
          this.$store.state.authentication.user.currEcopledge
      )
      return this.getEcopledgeById(
        this.$store.state.authentication.user.currEcopledge
      )
    },
    myStyles() {
      return {
        height: '400px',
        position: 'relative'
      }
    }
  },
  created: function() {
    this.doAjax()
    this.$store.dispatch('ecopledges/getUserEcopledges')
    this.$store.dispatch('ecopledges/getEcopledge')
    this.$store.dispatch('records/getEcopledgeRecords')
    this.fillData()
  },
  methods: {
    ...mapActions('ecopledges', ['getUserEcopledges']),
    ...mapActions('ecopledges', ['getEcopledge']),
    ...mapActions('ecopledges', ['updateEcopledge']),
    ...mapActions('records', ['getEcopledgeRecords']),

    fillData() {
      console.log('in fillData()')
      this.c_labels = []
      this.c_reuseData = []
      this.c_plasticsData = []
      this.sortedRecords = []
      this.loaded = false
      this.sortedRecords = this.records
      this.sortedRecords.sort(function(a, b) {
        const c = new Date(a.createTimestamp)
        const d = new Date(b.createTimestamp)
        return c - d
      })

      // console.log('fillData() .. ' + this.records.length)
      try {
        for (let i = 0; i < this.records.length; i += 1) {
          this.c_labels.push(this.records[i].label)
          this.c_reuseData.push(Number(this.records[i].valueTarget))
          this.c_plasticsData.push(Number(this.records[i].valueActual))
        }

        // console.log('after for loop')
        this.message = {
          labels: this.c_labels,
          datasets: [
            {
              label: 'Target',
              backgroundColor: '#00dd00',
              data: this.c_reuseData
            },
            {
              label: 'Actual',
              backgroundColor: '#FF1744',
              data: this.c_plasticsData
            }
          ]
        }

        this.loaded = true
        // this.performingRequest = false
      } catch (err) {
        console.log('try error: ' + err)
        // this.loaded = true
        // alert('try catch' + e)
      }
    },

    saveInline() {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancelInline() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    openInline() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    closeInline() {
      console.log('Dialog closed')
    },
    // object.assign fills in the empty object with the properties of item
    editItem(item, dbox = true) {
      console.log('...in editItem()' + item.id)
      // this.schoolId = item.id
      this.enteredTarget = Number(item.valueTarget)
      this.enteredActual = Number(item.valueActual)
      this.editedIndex = this.records.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = dbox
    },
    save() {
      // console.log('...in save()' + this.schoolId + this.enteredTarget + this.enteredActual,);
      // db.currentSchool.doc(this.schoolId).update({
      //   valueTarget: this.enteredTarget,
      //   valueActual: this.enteredActual
      // })
      this.close()
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    doAjax() {
      this.isLoading = true
      // simulate AJAX
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
}
</script>
<style lang="scss">
#dashboard .flex {
  margin-bottom: 2px;
}
#ourdashboard {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c5034;
  margin-top: 0px;
}
</style>
